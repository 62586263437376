import { useEffect, useState, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
  IconButton,
} from "@mui/material";
import Iconify from "../Iconify";
import { useStationAuth } from "../../contexts/StationAuthContext";
import { useGetBalance } from "../../contexts/BalanceContext";
import PetImage from "./PetImage";
import PetSelector from "./PetSelector";
import requiredCandyForLevel from "./requiredCandy.json";
import { useApplyCandyMutation } from "../../generated/graphql";
import { AnimatePresence, m } from "framer-motion";
import StationDetails from "../home/StationDetails";
import TermsAndConditions from "../Terms&Conditions";
import SweepstakesRules from "../SweepstakesRules";

export default function Incubator({
  tutorialModeStep,
  setTutorialModeStep,
}: {
  tutorialModeStep: number;
  setTutorialModeStep: (tutorialModeStep: number) => void;
}) {
  const [candies, setCandies] = useState<any>([]);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showSweepstakesRules, setShowSweepstakesRules] = useState(false);

  const [selectedPet, setSelectedPet] = useState<any | null>(null);
  const [displayMessage, setDisplayMessage] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [showLevelUp, setShowLevelUp] = useState(false);
  const [isFlashing, setIsFlashing] = useState(false);
  const [confirmFeedAllOpen, setConfirmFeedAllOpen] = useState(false);
  const [feedCounter, setFeedCounter] = useState(0);
  const [floatingNumbers, setFloatingNumbers] = useState<
    Array<{
      id: number;
      position: { top: number; left: number };
    }>
  >([]);

  const { balance } = useGetBalance();
  const { playerId } = useStationAuth();
  const audioRef = useRef<HTMLAudioElement>(null);
  const isInGame = useLocation().pathname.includes("/game");
  const [{ data: feedPetData }, applyCandy] = useApplyCandyMutation();

  const showFeedAll =
    feedCounter > 5 && balance?.candy && parseInt(balance?.candy) > 5;

  const isMaxLevel = selectedPet?.level + 1 === 5;
  const candyToNextLevel = selectedPet?.candyToNextLevel;
  const requiredCandy = requiredCandyForLevel.find(
    (c) => c.level === selectedPet?.level
  )?.requiredCandy;
  const xpPercentage = requiredCandy
    ? ((requiredCandy - candyToNextLevel) / requiredCandy) * 100
    : isMaxLevel
    ? 100
    : 0;

  const showTemporaryMessage = (message: string) => {
    setDisplayMessage(message);
    setTimeout(() => setDisplayMessage(null), 2000);
  };

  const addFloatingNumber = () => {
    const newPosition = {
      top: Math.random() * 200 - 100,
      left: Math.random() * 200 - 100,
    };
    const newFloatingNumber = {
      id: Date.now(),
      position: newPosition,
    };
    setFloatingNumbers((prev) => [...prev, newFloatingNumber]);
    setTimeout(() => {
      setFloatingNumbers((prev) =>
        prev.filter((num) => num.id !== newFloatingNumber.id)
      );
    }, 3000);
  };

  const playFeedSound = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play().catch((error) => {
        console.warn("Audio playback failed:", error);
      });
    }
  };

  const handleRandomMessage = useCallback(() => {
    if (Math.random() > 0.2) return null;
    const randomMessages = [
      "Yum!!!",
      "Thank you!",
      "More please!",
      "<3",
      ":)",
      "Candy is good!",
      "So tasty!",
      "Yummy!",
    ];
    return randomMessages[Math.floor(Math.random() * randomMessages.length)];
  }, []);

  const onClickFeedPet = async (candy: number) => {
    if (isInGame || loading || isMaxLevel) return;
    if (!balance?.candy || parseInt(balance?.candy) === 0) {
      showTemporaryMessage("You have no candy");
      return;
    }
    if (!playerId) throw new Error("Player ID is required");

    try {
      setLoading(true);
      setIsFlashing(true);
      setCandies([...candies, { id: Date.now() }]);

      setConfirmFeedAllOpen(false);
      setTimeout(() => {
        setIsFlashing(false);
        setCandies([]);
      }, 200);

      const randomMessage = handleRandomMessage();
      if (randomMessage) showTemporaryMessage(randomMessage);
      setFeedCounter(feedCounter + 1);
      addFloatingNumber();
      playFeedSound();

      const res = await applyCandy({
        playerId,
        petId: selectedPet?.id.toString(),
        candy: Math.min(candy, candyToNextLevel),
      });
      if (res?.error) throw new Error(res.error.message);
      setSelectedPet(res?.data?.applyCandy[0]);

      if (tutorialModeStep === 2) {
        setTimeout(() => {
          setTutorialModeStep(3);
        }, 1000);
      }

      if (
        res?.data?.applyCandy[0]?.candyToNextLevel &&
        res?.data?.applyCandy[0]?.candyToNextLevel >
          selectedPet?.candyToNextLevel
      ) {
        setShowLevelUp(true);
        setTimeout(() => setShowLevelUp(false), 2000);
      }
    } catch (error) {
      console.error("Error:", error);
      // toast.error(error?.message?.replace("[GraphQL]", ""));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      showTemporaryMessage(isInGame ? "Zzzzzzzz..." : "Feed me candy!");
    }, 60000);

    return () => clearInterval(interval);
  }, [isInGame]);

  return (
    <>
      <AnimatePresence>
        <m.div
          className="relative w-full h-auto select-none"
          initial={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 1 }}
        >
          {/* Candy */}
          <AnimatePresence>
            {candies.map((candy: any, index: number) => (
              <m.div
                key={candy.id}
                className="absolute top-0 right-1/2 transform -translate-x-1/2 z-50"
                initial={{ y: -50, rotate: 0, opacity: 1 }}
                animate={{ y: 250, rotate: 360 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1, ease: "easeInOut" }}
              >
                <img
                  className="float"
                  src="/images/sidebar/candy.png"
                  alt="Candy"
                  width="100px"
                />
              </m.div>
            ))}
          </AnimatePresence>
        </m.div>
      </AnimatePresence>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
          opacity: isInGame ? 0.5 : 1,
          animation: "fadeIn 0.5s ease-in",
        }}
      >
        <audio ref={audioRef} src="/sounds/crunch.wav" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            mt: 3,
            position: "relative",
          }}
        >
          {tutorialModeStep === 3 && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: -550,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                animation: "fadeIn 0.5s ease-in",
                opacity: 1,
              }}
            >
              <Box
                sx={{
                  border: "1px solid #000000",
                  borderRadius: "10px",
                  padding: "20px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "24px",
                    fontWeight: "bold",
                    textTransform: "none",
                  }}
                >
                  Every candy you feed gets me
                  <br />1 point closer to my next level!
                </Typography>

                <Button
                  onClick={() => {
                    setTutorialModeStep(4);
                    setTimeout(() => {
                      setTutorialModeStep(0);
                    }, 3000);
                  }}
                  size="large"
                  variant="contained"
                  sx={{
                    backgroundColor: "#5375c3",
                    color: "#FFFFFF",
                    mt: 2,
                    fontWeight: "bold",
                    width: "100px",
                    float: "right",
                  }}
                >
                  Next
                </Button>
              </Box>
              <Box className="left-right-float">
                <Iconify
                  icon="material-symbols:line-end-arrow-notch-rounded"
                  width={150}
                  height={150}
                  style={{
                    color: "#FFFFFF",
                  }}
                />
              </Box>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              backgroundColor: "#4372e1",
              height: "50px",
              borderRadius: "20px",
              position: "relative",
              overflow: "hidden",
              padding: "10px",
              border: "7px solid #4372e1",
              animation: isFlashing ? "flash 0.2s ease-in-out" : "none",
              "@keyframes flash": {
                "0%": {
                  filter: "brightness(1)",
                },
                "50%": {
                  filter: "brightness(1.5)",
                },
                "100%": {
                  filter: "brightness(1)",
                },
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                height: "100%",
                width: `${xpPercentage}%`,
                backgroundColor: "#31ff93",
                transition: "width 0.3s ease-in-out",
                boxShadow: "0 0 10px 8px #31ff93",
              }}
            />

            <Typography
              sx={
                isFlashing
                  ? {
                      opacity: 0.8,
                      fontStyle: "italic",
                      fontWeight: "bold",
                      color: "#31ff93",
                      textShadow: "0 0 10px #31ff93",
                    }
                  : {
                      opacity: 0.8,
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }
              }
            >
              {requiredCandy ? requiredCandy - candyToNextLevel : 0} /{" "}
              {requiredCandy} XP
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            flex: 1,
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mb: 2,
            }}
          >
            <Typography sx={{ fontSize: "25px", fontWeight: "bold" }}>
              {selectedPet?.level ? "Level " + (selectedPet?.level + 1) : ""}
            </Typography>
            <Typography sx={{ fontSize: "20px" }}>
              {selectedPet?.level === 0
                ? "Baby"
                : selectedPet?.level > 0
                ? "Adult"
                : ""}
              {selectedPet?.name}
            </Typography>
          </Box>
          <Button
            key={selectedPet?.id}
            disabled={loading}
            onClick={() => onClickFeedPet(1)}
            sx={{
              position: "relative",
              m: 1,
              mt: 0,
              height: "250px",
              width: "250px",
              borderRadius: "50%",
              boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
              background:
                "linear-gradient(225deg, rgba(150,187,255,1) 34%, rgba(87,127,167,1) 74%)",
              transition: "all 0.5s ease-in-out",
              "&:hover": {
                filter: "brightness(1.2)",
              },
            }}
          >
            {displayMessage && (
              <Box>
                <Box
                  sx={{
                    zIndex: 1,
                    position: "absolute",
                    top: -10,
                    left: 0,
                    background: "#FFF",
                    borderRadius: "25px",
                    padding: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
                    minWidth: "125px",
                    minHeight: "45px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      lineHeight: "14px",
                      textTransform: "none",
                    }}
                  >
                    {displayMessage}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: 45,
                    left: 15,
                    background: "#FFF",
                    borderRadius: "25px",
                    height: "25px",
                    width: "25px",
                    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: 75,
                    left: 30,
                    background: "#FFF",
                    borderRadius: "25px",
                    height: "15px",
                    width: "15px",
                    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
                  }}
                />
              </Box>
            )}

            {selectedPet && (
              <PetImage pet={selectedPet} height={"250px"} width={"250px"} />
            )}

            {tutorialModeStep === 2 && (
              <Box
                sx={{
                  position: "absolute",
                  top: 50,
                  left: -475,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  animation: "fadeIn 0.5s ease-in",
                  opacity: 1,
                  zIndex: 1202,
                  pointerEvents: "none",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #000000",
                    borderRadius: "10px",
                    padding: "20px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "24px",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    Click me to feed me a candy!
                  </Typography>
                </Box>
                <Box className="left-right-float">
                  <Iconify
                    icon="material-symbols:line-end-arrow-notch-rounded"
                    width={150}
                    height={150}
                    style={{
                      color: "#FFFFFF",
                    }}
                  />
                </Box>
              </Box>
            )}

            {floatingNumbers.map((floatingNum) => (
              <Typography
                key={floatingNum.id}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: `translate(calc(-50% + ${floatingNum.position.left}px), calc(-50% + ${floatingNum.position.top}px))`,
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  fontSize: "75px",
                  fontStyle: "italic",
                  animation: `floatUp${floatingNum.id} 3s ease-out`,
                  textShadow: "0 0 10px #FFFFFF",
                  [`@keyframes floatUp${floatingNum.id}`]: {
                    "0%": {
                      opacity: 1,
                      transform: `translate(calc(-50% + ${floatingNum.position.left}px), calc(-50% + ${floatingNum.position.top}px))`,
                    },
                    "100%": {
                      opacity: 0,
                      transform: `translate(calc(-50% + ${floatingNum.position.left}px), calc(-50% + ${floatingNum.position.top}px - 50px))`,
                    },
                  },
                }}
              >
                +1
              </Typography>
            ))}
            {showLevelUp && (
              <img
                src="/images/sidebar/levelup.png"
                alt="Level Up"
                height="250px"
                width="250px"
                style={{ position: "absolute", top: 0, left: 0 }}
              />
            )}
          </Button>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {showFeedAll ? (
              <Button
                variant="contained"
                sx={{
                  m: 2,
                  backgroundColor: "#5375c3",
                  fontWeight: "bold",
                  position: "relative",
                }}
                onClick={() => setConfirmFeedAllOpen(true)}
              >
                Feed All Candy!
                <img
                  src="/images/sidebar/candy.png"
                  alt="candy"
                  className="float"
                  style={{
                    position: "absolute",
                    top: -25,
                    right: -25,
                    height: "50px",
                    width: "50px",
                  }}
                />
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{
                  m: 2,
                  backgroundColor: "#5375c3",
                  fontWeight: "bold",
                }}
                onClick={() => onClickFeedPet(1)}
              >
                Feed Me
              </Button>
            )}
          </Box>

          <PetSelector
            selectedPet={selectedPet}
            setSelectedPet={setSelectedPet}
          />
        </Box>

        <Box
          sx={{
            flex: 1,
          }}
        />

        {/* Sweepstakes Rules */}
        <Button
          variant="outlined"
          className="slanted-button"
          onClick={() => {
            setShowSweepstakesRules(true);
          }}
          sx={{
            border: "1px solid #FFF",
            mb: 1,
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            Sweepstakes Rules
          </Typography>
        </Button>
        {/* Terms & Conditions */}
        <Button
          variant="outlined"
          className="slanted-button"
          onClick={() => {
            setShowTermsAndConditions(true);
          }}
          sx={{
            border: "1px solid #FFF",
            mb: 0.5,
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            Terms & Conditions
          </Typography>
        </Button>

        {/* Station Details */}
        <StationDetails />

        <TermsAndConditions
          showTermsAndConditions={showTermsAndConditions}
          setShowTermsAndConditions={setShowTermsAndConditions}
        />

        <SweepstakesRules
          showSweepstakesRules={showSweepstakesRules}
          setShowSweepstakesRules={setShowSweepstakesRules}
        />

        <Dialog
          open={confirmFeedAllOpen}
          onClose={() => setConfirmFeedAllOpen(false)}
        >
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Feed All Candy?
              <IconButton onClick={() => setConfirmFeedAllOpen(false)}>
                <Iconify icon="mdi:close" />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ minWidth: "375px", mt: 2 }}>
            <Typography>
              Are you sure you want to feed all your candy?
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                mt: 2,
                mb: 4,
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
                borderRadius: "10px",
                backgroundColor: "#4d6d95",
              }}
            >
              <img
                src="/images/sidebar/candy.png"
                alt="candy"
                className="float"
                style={{
                  height: "75px",
                  width: "75px",
                }}
              />
              <Typography sx={{ fontSize: "25px", fontWeight: "bold", mr: 2 }}>
                x{" "}
                {candyToNextLevel > balance?.candy!
                  ? balance?.candy
                  : candyToNextLevel}
              </Typography>
            </Box>

            <DialogActions
              sx={{
                m: 0,
                p: "0px !important",
              }}
            >
              <Button
                onClick={() => setConfirmFeedAllOpen(false)}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (!balance?.candy) return;
                  const allCandyToFeed =
                    candyToNextLevel > balance?.candy
                      ? balance?.candy
                      : candyToNextLevel;
                  onClickFeedPet(parseInt(allCandyToFeed));
                }}
              >
                Feed All Candy
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}
