import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, CircularProgress } from "@mui/material";
import { listen } from "@tauri-apps/api/event";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const UpdateModal = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Listen for the custom event from Rust
    const unlisten = listen("show-update-modal", () => {
      setOpen(true);
    });

    return () => {
      unlisten.then((fn) => fn());
    };
  }, []);

  return (
    <Modal
      open={open}
      aria-labelledby="update-modal-title"
      aria-describedby="update-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="update-modal-title"
          variant="h6"
          component="h2"
          gutterBottom
        >
          Software Update Available
        </Typography>
        <Typography id="update-modal-description" sx={{ mt: 2, mb: 3 }}>
          We're about to perform a software update. Upon the game reloading you
          will have to re-log in to continue playing. Please do not close the
          application.
        </Typography>
        <CircularProgress />
      </Box>
    </Modal>
  );
};

export default UpdateModal;
